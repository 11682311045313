import React from "react";

export const Ribbon = (props) => {
  return (
    <div className="ribbon">
      {/* <button onClick={props.toggleAlert}>We have a winner!</button> */}
      <span>Draw closes 31st January</span>
    </div>
  );
};
